import { Provider } from '@src-v2/types/enums/provider';

export const getProviderDisplayName = (providerKey: string, fallback?: string) => {
  if (providersDisplayName[providerKey]) {
    return providersDisplayName[providerKey];
  }

  console.warn('Unknown provider key:', providerKey);
  return fallback ?? providerKey;
};

const providersDisplayName: { [p in Provider]: string } = {
  [Provider.Unresolved]: '',
  [Provider.AzureDevops]: 'Azure Devops',
  [Provider.AzureDevopsServer]: 'Azure Devops',
  [Provider.BitbucketServer]: 'Bitbucket',
  [Provider.BitbucketCloud]: 'Bitbucket',
  [Provider.Github]: 'GitHub',
  [Provider.GithubEnterprise]: 'GitHub Enterprise Server',
  [Provider.GithubSecrets]: 'GitHub Secret Scanning',
  [Provider.Gitlab]: 'GitLab',
  [Provider.GitlabServer]: 'GitLab Self-Managed',
  [Provider.GitlabSast]: 'GitLab SAST',
  [Provider.GitlabSca]: 'GitLab Dependency Scanning',
  [Provider.GitlabDast]: 'GitLab DAST',
  [Provider.GitlabSecretDetection]: 'GitLab Secret Detection',
  [Provider.Perforce]: 'Perforce',
  [Provider.Jira]: 'Jira',
  [Provider.JiraServer]: 'Jira',
  [Provider.Slack]: 'Slack',
  [Provider.Teams]: 'Teams',
  [Provider.GoogleChat]: 'Google Chat',
  [Provider.Checkmarx]: 'Checkmarx',
  [Provider.Veracode]: 'Veracode',
  [Provider.Fortify]: 'Fortify',
  [Provider.Snyk]: 'Snyk',
  [Provider.Mend]: 'Mend',
  [Provider.Mend2]: 'Mend2',
  [Provider.BlackDuck]: 'Black Duck',
  [Provider.SonarCloud]: 'SonarCloud',
  [Provider.SonarQube]: 'SonarQube',
  [Provider.ShiftLeft]: 'ShiftLeft',
  [Provider.Semgrep]: 'Semgrep',
  [Provider.AWS]: 'AWS',
  [Provider.GitPlain]: '',
  [Provider.LocalKubeConfig]: '',
  [Provider.Okta]: 'Okta',
  [Provider.Wiz]: 'Wiz',
  [Provider.JFrog]: 'JFrog Artifactory',
  [Provider.JFrogXray]: 'JFrog Xray',
  [Provider.PrismaCloud]: 'Prisma Cloud',
  [Provider.CrowdStrike]: 'CrowdStrike',
  [Provider.Jenkins]: 'Jenkins',
  [Provider.FindingsApi]: 'Findings API',
  [Provider.AzureActiveDirectory]: 'Azure AD',
  [Provider.AzureCloud]: 'Azure',
  [Provider.GoogleCloud]: 'Google Cloud',
  [Provider.SpringApiGateway]: 'Spring API Gateway',
  [Provider.NginxController]: 'NGINX controller',
  [Provider.TykApiGateway]: 'Tyk API Gateway',
  [Provider.ApiiroSca]: 'Apiiro',
  [Provider.Invicti]: 'Invicti',
  [Provider.SnykSca]: 'Snyk Open Source (SCA)',
  [Provider.SnykSast]: 'Snyk Code (SAST)',
  [Provider.SnykContainerScanning]: 'Snyk',
  [Provider.CodeQL]: 'CodeQL',
  [Provider.Dependabot]: 'Dependabot',
  [Provider.AppScanSast]: 'HCL AppScan SAST',
  [Provider.AppScanDast]: 'HCL AppScan DAST',
  [Provider.ManagedCheckov]: 'Managed Checkov',
  [Provider.ManagedSemgrep]: 'Managed Semgrep',
  [Provider.Splunk]: 'Splunk',
  [Provider.NetworkBroker]: 'NetworkBroker',
  [Provider.CircleCi]: 'Circle CI',
  [Provider.Qualys]: 'Qualys WAS',
  [Provider.SemgrepCode]: 'Semgrep Code',
  [Provider.SemgrepSupplyChain]: 'Semgrep Supply Chain',
  [Provider.PolarisSast]: 'Polaris fAST Static',
  [Provider.Bugcrowd]: 'Bugcrowd',
  [Provider.BurpSuite]: 'BurpSuite',
  [Provider.ServiceNow]: 'ServiceNow',
  [Provider.SonatypeLifecycle]: 'Sonatype Lifecycle',
  [Provider.SecureCodeWarrior]: 'SecureCodeWarrior',
  [Provider.ManualUpload]: 'Manual Entry',
  [Provider.HackerOne]: 'HackerOne',
  [Provider.NonameSecurity]: 'Noname Security',
  [Provider.Orca]: 'Orca',
  [Provider.Backstage]: 'Backstage',
  [Provider.SdElements]: 'SD Elements',
  [Provider.SaltSecurity]: 'Salt Security',
  [Provider.Tenable]: 'Tenable',
  [Provider.InsightVm]: 'InsightVM',
  [Provider.Nexpose]: 'Nexpose',
};
